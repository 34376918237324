import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import {
  addSegmentInConversation,
  deleteSegmentInConversation,
} from "./conversation";

const initialState = {
  segments: [],
  statusSegments: "idle",
  statusDeleteSegments: "idle",
  statusCreateSegment: "idle",
  statusAddSegmentToConversation: "idle",
  errorAddSegmentToConversation: null,
  statusDeleteSegmentFromConversation: "idle",
  errorDeleteSegmentFromConversation: null,
  errorCreateSegment: null,
  errorDeleteSegments: null,
  errorSegments: null,
};

export const getAllSegments = createAsyncThunk(
  "segments/getAllSegments",
  async (websiteId) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteId}/segments`);

      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createSegments = createAsyncThunk(
  "segments/createSegments",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteId}/conversations/${body.conversationId}/segments`,
        { name: body.segment }
      );

      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(getAllSegments(body.websiteId));
        thunkApi.dispatch(
          addSegmentInConversation({
            segment: data,
            conversationId: body.conversationId,
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addSegmentToConversation = createAsyncThunk(
  "segments/addSegmentsToConversation",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/conversations/${body.conversationID}/segments/${body.segmentID}/add-segment`
      );

      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(
          addSegmentInConversation({
            segment: data.segments[data.segments.length - 1],
            conversationId: body.conversationID,
          })
        );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteSegments = createAsyncThunk(
  "segments/deleteSegments",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteId}/segments/${body.segment._id}`
      );

      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(getAllSegments(body.websiteId));
        thunkApi.dispatch(
          deleteSegmentInConversation({
            segment: body.segment,
            conversationId: body.conversationId,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteSegmentFromConversation = createAsyncThunk(
  "segments/deleteSegmentFromConversation",
  async (body, thunkApi) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/conversations/${body.conversationID}/segments/${body.segment?._id}`
      );

      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(
          deleteSegmentInConversation({
            segment: body.segment,
            conversationId: body.conversationID,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "segments",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllSegments.pending]: (state) => {
      state.statusSegments = "loading";
    },
    [getAllSegments.fulfilled]: (state, action) => {
      state.statusSegments = "succeeded";
      state.segments = action.payload;
    },
    [getAllSegments.rejected]: (state, action) => {
      state.statusSegments = "failed";
      state.errorSegments = action.error.message;
    },
    [createSegments.pending]: (state) => {
      state.statusCreateSegment = "loading";
    },
    [createSegments.fulfilled]: (state, action) => {
      state.statusCreateSegment = "succeeded";
    },
    [createSegments.rejected]: (state, action) => {
      state.statusCreateSegment = "failed";
      state.errorCreateSegment = action.error.message;
    },
    [addSegmentToConversation.pending]: (state) => {
      state.statusAddSegmentToConversation = "loading";
    },
    [addSegmentToConversation.fulfilled]: (state, action) => {
      state.statusAddSegmentToConversation = "succeeded";
    },
    [addSegmentToConversation.rejected]: (state, action) => {
      state.statusAddSegmentToConversation = "failed";
      state.errorAddSegmentToConversation = action.error.message;
    },
    [deleteSegments.pending]: (state) => {
      state.statusDeleteSegments = "loading";
    },
    [deleteSegments.fulfilled]: (state) => {
      state.statusDeleteSegments = "succeeded";
    },
    [deleteSegments.rejected]: (state, action) => {
      state.statusDeleteSegments = "failed";
      state.errorDeleteSegments = action.error.message;
    },
    [deleteSegmentFromConversation.pending]: (state) => {
      state.statusDeleteSegmentFromConversation = "loading";
    },
    [deleteSegmentFromConversation.fulfilled]: (state) => {
      state.statusDeleteSegmentFromConversation = "succeeded";
    },
    [deleteSegmentFromConversation.rejected]: (state, action) => {
      state.statusDeleteSegmentFromConversation = "failed";
      state.errorDeleteSegmentFromConversation = action.error.message;
    },
  },
});

export const reducer = slice.reducer;
export default slice;
